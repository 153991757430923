<template>
    <div class="offline-training">
        <div class="offline-title" @click="onClickLeft()">
            <img src="../asset/image/left.png" alt="">
            <span>返回</span>
        </div>
        <div class="offline-content">
            <div class="title">
                <p class="name">{{name}}</p>
            </div>
            <div class="content">
                <p class="time">考试时间：{{examTime}}</p>
                <p class="volume_up">考试须知：{{examNotice}}</p>
                <div class="start-button">
                    <el-button type="primary" class="start-primary-button" :loading="drawLoading" @click="startDrawingQuestion()">开始抽题</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'offlineTraining',
    data(){
        return {
            drawLoading:false,//开始抽题加载状态
            name:'',//考试名称
            examNotice:'',//考试须知
            examTime:'',//考试时间
            id:'',//试卷的id
            setmId:'',//开始抽题的id
        }
    },
    async created(){
        if(this.$route.query){
            this.id = this.$route.query.id ? this.$route.query.id : ''
        }
        await this.findClassExamInfo();
    },
    methods:{
        onClickLeft(){
            this.$router.push({
                path:'/learning/taskDetail',
                query:JSON.parse(this.$route.query.taskQuery)
            })
        },
        // 获取试卷信息
        findClassExamInfo(){
            const params = {
                id:this.id
            }
            this.$api.offlineTraining.findClassExamInfo(params).then(res => {
                console.log(res);
                if(res.success){
                    this.name = res.data.name
                    this.examNotice = res.data.examNotice
                    this.setmId = res.data.id
                    this.examTime = res.data.examTime
                }
            })
        },
        // 开始抽题按钮
        startDrawingQuestion(){
            this.drawLoading = true;
            const params = {
                id:this.setmId
            }
            this.$api.offlineTraining.extractQuestion(params).then(res => {
                if(res.success){
                    console.log(res.data);
                    this.drawLoading = false
                    this.$router.push({
                        path:'/offlineStem',
                        query:{
                            name:this.name,
                            id:this.setmId,
                            taskQuery:this.$route.query.taskQuery
                        }
                    })
                }
            })
        },
    }
}
</script>
<style lang="stylus" scoped src="../asset/css/offlineTraining.styl">

</style>