<template>
    <div class="offline-training">
        <div class="offline-title" @click="onClickLeft()">
            <img src="../asset/image/left.png" alt="">
            <span>返回</span>
        </div>
        <div class="offline-content">
            <div class="title">
                <p class="name">{{name}}</p>
            </div>
            <div class="content">
                <img src="../asset/image/success.png" alt="" class="img" v-if="evaluationResult == 1">
                <img src="../asset/image/fail.png" alt="" class="img" v-else-if="evaluationResult == 2">
                <img src="../asset/image/weiluru.png" alt="" class="img" v-else>
                <div class="test-result">
                    <span class="test">线下实训考试成绩：</span>
                    <span class="result-success" v-if="evaluationResult == 1">合格</span>
                    <span class="result-fail" v-else-if="evaluationResult == 2">不合格</span>
                    <span class="result-weiluru" v-else>暂无</span>
                </div>
                <a class="preview" @click="previewQuestion()">查看实训考试题目</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'offlineTrainingPreview',
    data(){
        return {
            evaluationResult:'',//0 未录入  1 合格  2 不合格
            evaluationResultId:'',
            name:'',
        }
    },
    async created() {
        await this.findExamScore()
    },
    methods:{
        // 返回
        onClickLeft(){
            this.$router.push({
                path:'/learning/taskDetail',
                query:JSON.parse(this.$route.query.taskQuery)
            })
        },
        // 返回成绩信息
        findExamScore(){
            const params = {
                id:this.$route.query.id ? this.$route.query.id : ''
            }
            this.$api.offlineTraining.findExamScore(params).then(res => {
                if(res.success){
                    console.log(res.data);
                    this.evaluationResult = res.data.evaluationResult
                    this.evaluationResultId = res.data.id
                    this.name = res.data.name
                }
            })
        },
        // 查看实训考试题目
        previewQuestion(){
            this.$router.push({
                path:'/offlineTraining/preview/result',
                query:{
                    id:this.evaluationResultId,
                    taskQuery:this.$route.query.taskQuery,
                    name:this.name
                }
            })
        }
    }
}
</script>
<style lang="stylus" scoped src="../asset/css/offlinePreview.styl">

</style>