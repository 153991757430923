<template>
    <div class="offline-training">
        <div class="offline-title" @click="onClickLeft()">
            <img src="../asset/image/left.png" alt="">
            <span>返回</span>
        </div>
        <div class="offline-content">
            <div class="title">
                <p class="name">{{name}}</p>
            </div>
            <div class="confirm-content">
                <p class="question">考试题目：</p>
                <div class="question-content" v-html="question">
                </div>
                <div class="confirm-button">
                    <el-button class="confirm-primary-button" type="primary" @click="questionConfirm()">考试完成</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'offlineStem',
    data(){
        return {
            name:'',//考试名称
            question:'',//题干
        }
    },
    async created(){
        this.name = this.$route.query.name ? this.$route.query.name : ''
        await this.extractQuestion()
    },
    methods:{
        extractQuestion(){
            const params = {
                id:this.$route.query.id ? this.$route.query.id :'',
            }
            this.$api.offlineTraining.extractQuestion(params).then(res => {
                if(res.success){
                    this.question = res.data.question
                }
            })
        },
        onClickLeft(){
            this.$router.push({
                path:'/learning/taskDetail',
                query:JSON.parse(this.$route.query.taskQuery)
            })
        },
        // 完成考试后跳转到任务目录页面
        questionConfirm(){
            this.$router.push({
                path:'/learning/taskDetail',
                query:JSON.parse(this.$route.query.taskQuery)
            })
        }
    }
}
</script>
<style lang="stylus" scoped src="../asset/css/offlineTraining.styl">

</style>