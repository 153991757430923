<template>
    <div class="offline-training">
        <div class="offline-title" @click="onClickLeft()">
            <img src="../asset/image/left.png" alt="">
            <span>返回</span>
        </div>
        <div class="offline-content">
            <div class="title">
                <p class="name">{{name}}</p>
            </div>
            <div class="content">
                <p class="question">考试题目：</p>
                <div class="question-content" v-html="title">
                </div>
                <div class="answer-content">
                    <!-- <div class="right-answer">
                        <p class="right">正确答案:</p>
                        <div class="answer" v-html="rightAnswer">
                        </div>
                    </div> -->
                    <!-- <div class="border"></div> -->
                    <div class="right-answer">
                        <p class="right">试题解析:</p>
                        <div class="answer">
                            <span v-if="remark" v-html="remark"></span>
                            <span v-else>无</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'offlinePreviewResult',
    data(){
        return {
            name:'',
            title:'',//题干
            rightAnswer:'',//正确答案
            remark:'',//试题解析
        }
    },
    async created() {
        if(this.$route.query){
            this.name = this.$route.query.name
        }
        await this.findQuestion()
    },
    methods:{
        // 返回
        onClickLeft(){
            this.$router.push({
                path:'/learning/taskDetail',
                query:JSON.parse(this.$route.query.taskQuery)
            })
        },
        // 查看实训考试题目
        findQuestion(){
            const params = {
                id:this.$route.query.id ? this.$route.query.id :''
            }
            this.$api.offlineTraining.findQuestion(params).then(res => {
                if(res.success){
                    console.log(res.data);
                    this.title = res.data.title
                    this.rightAnswer = res.data.rightAnswer
                    this.remark = res.data.remark
                }
            })
        }
    }
}
</script>
<style lang="stylus" scoped src="../asset/css/offlinePreviewResult.styl">
</style>